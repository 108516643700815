import React from 'react'
import PropTypes from 'prop-types'

import DataList from '../shared/DataList'
import DataListRow from '../shared/DataListRow'

import SimpleContainer from '../shared/SimpleContainer'
import FormContainer from '../shared/FormContainer'
import FormRow from '../shared/FormRow'
import FieldBase from '../shared/FieldBase'
import InputGroupMultiple from '../shared/InputGroupMultiple'
import FusedFields from '../shared/fused-fields'

import { formatMoney, numOrZero } from 'lib/utility'
import { resolveCommission } from 'lib/utility.statement'
import { statementLineItems_specs } from 'lib/list_specifications'

function adminObjectRequired(props, propName) {
  if (props['mode'] === 'admin') {
    if (props[propName] === undefined) { return new Error('The prop `' + propName + '` is required') }
    if (typeof(props[propName]) !== 'object') { return new Error('The prop `' + propName + '` needs to be an object') }
  }
}

function adminFunctionRequired(props, propName) {
  if (props['mode'] === 'admin') {
    if (props[propName] === undefined) { return new Error('The prop `' + propName + '` is required') }
    if (typeof(props[propName]) !== 'function') { return new Error('The prop `' + propName + '` needs to be a function') }
  }
}

StatementLineItems.propTypes = {
  mode: PropTypes.string.isRequired,
  statement: PropTypes.object.isRequired,
  commission_scheme: adminObjectRequired,
  setFieldValue: adminFunctionRequired,
  lineitemAdd: adminFunctionRequired,
  lineitemRemove: adminFunctionRequired,
}

function StatementLineItems({ mode, statement, commission_scheme, setFieldValue, lineitemAdd, lineitemRemove }) {
  const _lineitemAdd = () => {
    const data = statement.content.admin_info.data
    if (data.desc !== '' && numOrZero(data.amount) !== 0) {
      lineitemAdd({ desc: data.desc, amount: data.amount }, statement.content.statement_id)
    }
  }

  const data = mode === 'admin' ? statement.content.admin_info.data : statement.content.main
  const lineitems = statement.content.lineitems
  const lots = statement.content.lots
  let lineitems_total = 0
  let bids_total = 0
  let commission_total = 0

  lineitems.map(function(line, i) {
    lineitems_total += line.amount ? +line.amount : 0
  })

  lots.map((item, i) => {
    bids_total += item.winning_bid ? numOrZero(item.winning_bid) : 0
    commission_total += mode === 'admin' ? (resolveCommission({ scheme: commission_scheme, hammer_price: item.winning_bid, adjustment: item.adjustment })) : item.final_commission
  })

  const total_due = bids_total - commission_total + lineitems_total

  const lineitem_data = <DataList
    useTable={true}
    tableStyle={{ fontSize: '13px' }}
    showHeader={true}
    errorCondition={lineitems.conditionFail}
    errorMessage={lineitems.errorMessage}
    isLoading={lineitems.isFetching}
    showTopPagingControl={false}
    showBottomPagingControl={false}
    data={lineitems}
    totalItemCount={statement.content.lineitem_count}
    metadata={statementLineItems_specs[mode].lineitems}
    datakey='id'
    removeFunction={lineitemRemove}
    pageSize={400}
  >
    <DataListRow />
  </DataList>

  const lineitem_adder = <FusedFields data={data} onUpdate={setFieldValue} onLeave={setFieldValue} options={{ group: 'admin_info' }}>
    <FormContainer rowClassName="fixed-gutter-5" size='small' noMargins={true}>
      <FormRow>
        <InputGroupMultiple className="col-lg-12">
          <FieldBase type="rawinput" className="form-control" name="desc" autoComplete="off" placeholder="Description" />
          <FieldBase type="rawinput" className="form-control" name="amount" data-type="float" autoComplete="off" placeholder="Amount" style={{ width: '100px' }} />
          <span className="input-group-btn" style={{ width: 'unset' }}>
            <button className="btn btn-u btn-u-sm btn-u-primary" onClick={_lineitemAdd} style={{ lineHeight: '24px' }}><span style={{fontWeight: 'bold'}}><span className="fa fa-fw fa-plus" /> Add</span></button>
          </span>
        </InputGroupMultiple>
      </FormRow>
    </FormContainer>
  </FusedFields>

  const lot_data = <SimpleContainer className='margin-bottom-5' style={{ borderBottom: '1px solid #AAA' }}>
    <DataList
      useTable={true}
      tableStyle={{ fontSize: '13px' }}
      showHeader={true}
      errorCondition={lots.conditionFail}
      errorMessage={lots.errorMessage}
      isLoading={lots.isFetching}
      showTopPagingControl={false}
      showBottomPagingControl={false}
      data={lots}
      totalItemCount={statement.content.lot_count}
      metadata={statementLineItems_specs[mode].lots}
      datakey='id'
      clickTargetTemplate='/catalog/lot/<param1>/<param2>'
      clickTargetParam1='auction_id'
      clickTargetParam2='lot_number'
      inputHandleUpdate={setFieldValue}
      inputHandleLeave={setFieldValue}
      pageSize={400}
    >
      <DataListRow globalCalcData={commission_scheme} />
    </DataList>
  </SimpleContainer>

  const total_data = <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
    <div style={{ flexGrow: '1' }}>
      <table style={{ width: '100%', marginTop: '0', flexGrow: '1' }}>
        <colgroup>
          <col style={{ width: '50%' }} />
          <col style={{ width: '50%' }} />
        </colgroup>
        <tbody>
          <tr>
            <td style={{ textAlign: 'right' }}>Sales:</td>
            <td style={{ textAlign: 'right', fontWeight: '900', paddingRight: '4px' }}>{formatMoney(bids_total)}</td>
          </tr>
          <tr>
            <td style={{ textAlign: 'right' }}>Commission:</td>
            <td style={{ textAlign: 'right', fontWeight: '900', paddingRight: '4px' }}>{formatMoney(-commission_total)}</td>
          </tr>
          <tr>
            <td style={{ textAlign: 'right' }}>Expenses/Credits:</td>
            <td style={{ textAlign: 'right', fontWeight: '900', paddingRight: '4px' }}>{lineitems_total >= 0 ? formatMoney(lineitems_total) : '(' + formatMoney(-lineitems_total) + ')'}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div style={{ flexGrow: '0' }}>
      <table style={{ width: '100%', marginTop: '0' }}>
        <colgroup>
          <col style={{ width: '50%' }} />
          <col style={{ width: '50%' }} />
        </colgroup>
        <tbody>
          <tr style={{ borderTop: '1px solid #AAA', backgroundColor: '#EEE' }}>
            <td style={{ textAlign: 'right', fontWeight: '900', fontSize: '16px' }}>Due Consignor:</td>
            <td style={{ textAlign: 'right', fontWeight: '900', fontSize: '16px', paddingRight: '4px' }}>{formatMoney(total_due)}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  return <SimpleContainer>
    {lot_data}
    <div className="fixed-gutter-5" style={{ display: 'inline-flex', width: '100%' }}>
      <div className="col-md-8">
        <SimpleContainer title='Misc Expenses/Credits' titleBackgroundColor='#f6da97' titleIcon='fa fa-fw fa-asterisk' style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
          <div style={{ flexGrow: '1' }}>
            {lineitem_data}
          </div>
          {mode === 'admin' ? lineitem_adder : null}
        </SimpleContainer>
      </div>
      <div className="col-md-4">
        <SimpleContainer title='Totals' titleBackgroundColor='#f6da97' titleIcon='fa fa-fw fa-usd' style={{ border: '1px solid #AAA', height: '100%', display: 'flex', flexDirection: 'column' }}>
          {total_data}
        </SimpleContainer>
      </div>
    </div>
  </SimpleContainer>
}

export default StatementLineItems
