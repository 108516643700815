import { round } from 'lib/utility'

export function resolveCommission({ scheme, hammer_price, adjustment=0 }={}) {
  return Math.max(hammer_price <= scheme.threshold_range1
    ? round(hammer_price * scheme.percent_range1, 2)
    : hammer_price <= scheme.threshold_range2
      ? round(hammer_price * scheme.percent_range2, 2)
      : hammer_price <= scheme.threshold_range3
        ? round(hammer_price * scheme.percent_range3, 2)
        : hammer_price <= scheme.threshold_range4
          ? round(hammer_price * scheme.percent_range4, 2)
          : hammer_price <= scheme.threshold_range5
            ? round(hammer_price * scheme.percent_range5, 2)
            : round(hammer_price * scheme.percent_range_top, 2), scheme.com_minimum) + +adjustment
}
